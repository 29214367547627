@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --theme-color: #508DBC;
    --color-logo: #00ABEB;
    --color-triada-rojo: #EB1747;
}

.bg-theme {
    background-color: var(--theme-color);
}
.text-theme {
    color: var(--theme-color)
}
.border-theme {
    border-color: var(--theme-color)
}

html, body {
    background-color: #416235;
    height: 100vh;
    overflow: hidden;
}


.container {
    /*
    width: 100%;
    max-width: 100%;
    height: 0;
    padding-top: 56.25025%;
    */
    position:relative;
    overflow: hidden;
    max-width: 100%;
}
.container > svg {
    /*
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
    */
    max-height: 100vh;
    margin: 0 auto;
}


.blink-area {
    animation: blink-opacity-svg 4s infinite;
    fill: #00ABEB;
}


@keyframes blink-opacity-svg {
    0% {
        fill-opacity: 0;
    }
    50% {
        fill-opacity: 0.5;
    }
    100% {
        fill-opacity: 0;
    }
}
.blink-area:hover {
    animation: none;
    transition: fill-opacity 300ms ease-in;
    fill-opacity: 0.9;
    cursor: pointer;
    fill: #EB1747 !important;
}



@keyframes zoom-and-fade {
    0% {
        filter: blur(1px);
    }
    50% {
        /* transform: scale(1.25); */
        /*filter: blur(2px);*/
    }
    100% {
        transform: scale(1.8);
        filter: blur(8px);
    }
}
@keyframes zoom-and-fade-out {
    0% {
        transform: scale(1.8);
        filter: blur(8px);
    }
    50% {
        /* transform: scale(1.25); */
        /*filter: blur(2px);*/
    }
    80% {
        transform: scale(1);
        filter: blur(1px);
    }
    100% {
        filter: none;
        display: none;
    }
}

#img-zoom {
    display: block;
    opacity: 1;
    animation: zoom-and-fade 300ms ease-in both;
}
/*
#img-zoom.open {
    display: block;
    opacity: 1;
    animation: zoom-and-fade 300ms ease-in both;
}
#img-zoom.close {
    animation: zoom-and-fade-out 300ms ease-in both;
}
*/


@keyframes fade-in-down {
    0% {
        opacity: 0;
        transform: translate3d(0, -80%, 0);
    }
    50% {
        opacity: 0.2;
        transform: translate3d(0, 0, 0);
    }
    100% {
        opacity: 1;
        /*transform: translate3d(0, 0, 0);*/
    }
}
@keyframes fade-out-up {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        transform: translate3d(0, -80%, 0);
    }
}

.modal-zoom {
    display: block;
    animation-delay: 300ms;
    animation-duration: 600ms;
    animation-fill-mode: both;
    animation-name: fade-in-down;
    animation-timing-function: ease-in-out;

    overflow: hidden;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

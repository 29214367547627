
@keyframes zoom-and-fade-millo {
    0% {
        filter: blur(0);
        opacity: 1;
    }
    99% {
        transform: scale(3.5) translateX(-30%) translateY(40%);
        filter: blur(8px);
        opacity: 1;
    }
    100% {
        transform: scale(1) translateX(0) translateY(0);
        filter: blur(20px);
    }
}

#img-millo-zoom {
    display: block;
    opacity: 1;
    animation: zoom-and-fade-millo 500ms ease-in forwards;
}

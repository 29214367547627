
@keyframes zoom-and-fade-stands {
    0% {
        filter: blur(0);
        opacity: 1;
    }
    99% {
        transform: scale(3.5) translateX(-33%) translateY(10%);
        filter: blur(8px);
        opacity: 1;
    }
    100% {
        transform: scale(1) translateX(0) translateY(0);
        filter: blur(20px);
    }
}

#img-stands-zoom {
    display: block;
    opacity: 1;
    animation: zoom-and-fade-stands 500ms ease-in forwards;
}

.stand-container {
    /*
    width: 100%;
    max-width: 100%;
    height: 0;
    padding-top: 56.25025%;
    */
    min-height: 100vh;
    position:relative;
    overflow: hidden;
    max-width: 100%;
    /*
    background: url("../img/stand-filete-x.jpg") top repeat-x;
    background-size: auto 100%;
     */
}
.stand-container > svg {
    /*
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
    */
    max-height: 100vh;
    margin: 0 auto;
}

.blink-area-stands {
    cursor: pointer;
    fill: transparent;
    stroke: none;
}



@keyframes zoom-and-fade-sala {
    0% {
        filter: blur(0);
        opacity: 1;
    }
    99% {
        transform: scale(3.5) translateX(-15%) translateY(5%);
        filter: blur(8px);
        opacity: 1;
    }
    100% {
        transform: scale(1) translateX(0) translateY(0);
        filter: blur(20px);
    }
}

#img-sala-zoom {
    display: block;
    opacity: 1;
    animation: zoom-and-fade-sala 500ms ease-in forwards;
}


#rotate-device-warning {
    display: none;
}
  
@media screen and (max-width: 640px) and (orientation: portrait) {
    #rotate-device-warning {
      display: flex;
      background-color: rgba(255, 255, 255, .8);
    }
}

@keyframes float-anim {
    0% {
        transform: translate(0px,5px);
    }
    50% {
        transform: translate(0px,-5px);
    }
    100% {
        transform: translate(0px,5px);
    }
}

#flecha {
    animation: float-anim 6s ease-in-out infinite;
}
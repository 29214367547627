


@keyframes zoom-and-fade-rexistro {
    0% {
        filter: blur(0);
        opacity: 1;
    }
    99% {
        transform: scale(3.5) translateX(20%) translateY(-18%);
        filter: blur(8px);
        opacity: 1;
    }
    100% {
        transform: scale(1) translateX(0) translateY(0);
        filter: blur(20px);
    }
}

#img-rexistro-zoom {
    opacity: 1;
    animation: zoom-and-fade-rexistro 500ms ease-in forwards;
}

#modal-zoom-rexistro {
    display: flex !important;
}